<template>
  <BillingSelectPayment />
</template>

<script>
import BillingSelectPayment from '@/components/billing/request/BillingSelectPayment'
export default {
  name: 'BillingPayment',

  components: { BillingSelectPayment },
}
</script>
