<template>
  <BillingSummaryDetail />
</template>

<script>
import BillingSummaryDetail from '@/components/billing/request/BillingSummaryDetail'

export default {
  name: 'BillingSummary',

  components: { BillingSummaryDetail },
}
</script>
