<template>
  <BillingSelectPatient />
</template>

<script>
import BillingSelectPatient from '@/components/billing/request/BillingSelectPatient'

export default {
  name: 'SelectPatient',

  components: { BillingSelectPatient },
}
</script>
